import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { postponedOutcomesMap, reasons, types } from '../../../constants/outcomes'
import { getStatusString, status  } from 'constants/statuses'
import { addChange } from 'app/store'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class AppointmentRejectedDialog extends React.Component {
  constructor (props) {
    super(props)
    this.currentUrl = window.location.href;
    
    if (this.currentUrl.includes("step")) {
      const outcomes = this.props.installation?.outcomes?.[
        this.props.installation?.outcomes?.length - 1
      ]?.notes;

      const reason = outcomes ? outcomes.split(".")[0] : ""

      this.state = { 
        reason: outcomes ? reason : "", 
        notes: outcomes && reason === getStatusString(status.REJECTED) ? outcomes.split(".").slice(1).join(".").trim() : "" 
      };
    } else {
      const reason = this.props.installation?.appointment?.outcomeDetail
      this.state = {
        reason: reason || "",
        notes: reason === getStatusString(status.REJECTED) ? this.props.installation?.appointment?.comments.trim() : ""
      };
    }
    this.baseState = this.state

    this.handleChange = this.handleChange.bind(this)
    this.handleExited = this.handleExited.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  get isMassAppointment () {
    return this.currentUrl.includes("step")
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })

    if (!this.isMassAppointment) {
      const { addChange, installation } = this.props;
      const { name, value } = event.target;
      // Add the primary change
      addChange(`appointment.${name}`, value, installation.id);
      // Handle specific case for 'outcomeDetail'
      if (name === 'outcomeDetail') {
        const reason = postponedOutcomesMap[value];
        const type = postponedOutcomesMap(reason);
        
        const changes = [
          { key: 'appointment.outcomeReason', value: reason },
          { key: 'appointment.outcomeType', value: type },
          { key: 'appointment.comments', value },
        ];
        // Apply changes in batch
        changes.forEach(({ key, value }) => addChange(key, value, installation.id));
      }
    }
  }

  handleExited () {
    this.reset()
  }

  handleSubmit () {
    if(!this.isMassAppointment){
      const { addChange } = this.props
      const id = this.props?.installation?.id
      addChange(
        'outcome.type',
        postponedOutcomesMap[this.state.reason],
        id
      )
      addChange('outcome.reason', reasons.completed.REJECTED, id)
      addChange('appointment.outcomeDetail', getStatusString(status.REJECTED), id)
      addChange('appointment.outcomeReason', reasons.completed.REJECTED, id)
      addChange('appointment.comments', this.state.notes.trim(), id)
      addChange('submitted', true, id)
    }

    const reason = reasons.completed.REJECTED
    const notes = `${getStatusString(status.REJECTED)}. ${this.state?.notes}`
    const type = types.COMPLETED
    this.props.onSubmit({ notes: notes, reason: reason, type: type })
    this.props.onClose()
  }

  reset () {
    this.setState(this.baseState)
  }

  render () {
    return (
      <Dialog
        aria-labelledby='postpone-dialog-title'
        fullWidth
        maxWidth='md'
        onClose={this.props.onClose}
        open={this.props.open}
        TransitionProps={{
          onExited: this.handleExited
        }}
      >
        <DialogTitle id='postpone-dialog-title'>Rejected install</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Notes'
            margin='normal'
            multiline
            name='notes'
            onChange={this.handleChange}
            rows={4}
            value={this.state?.notes}
            variant='outlined'
          />
        </DialogContent>

        <DialogActions>
          <Button
            color='primary'
            onClick={this.handleSubmit}
            variant='contained'
          >
            Confirm
          </Button>
          <Button onClick={this.props.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match.params.massId]
  if (!mass) return {}

  const collection = mass.collections.find(
    c => parseInt(c.id) === parseInt(ownProps.match.params.collectionId)
  )

  const installation = collection.installations.find(
    i => parseInt(i.id) === parseInt(ownProps.match.params.installationId)
  )

  return {
    collection: collection,
    installation: installation,
    mass: mass
  }
}

const mapDispatchToProps = dispatch => ({
  addChange: (name, value, id) => dispatch(addChange(name, value, id))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentRejectedDialog)
)
