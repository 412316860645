import {
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Step,
  StepContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Box } from '@mui/system'
import CliHelper from 'components/pages/InstallPage/CliHelper'
import StepperButton from 'components/steppers/StepperButton'
import React from 'react'

/** Style object for box text styling. */
const boxStyle = { fontSize: 18, lineHeight: 1.65 }

/** Style object for highlighting inline spans. */
const spanStyle = {
  backgroundColor: theme => alpha(theme.palette.secondary.main, 0.05),
  borderBottom: '2px solid',
  borderColor: theme => alpha(theme.palette.secondary.main, 0.15),
  color: 'secondary.main',
  paddingBottom: 0.25,
  paddingLeft: 0.5,
  paddingRight: 0.5
}

/** Style object for styling numbers in table. */
const tableStyle = {
  fontSize: 16,
  fontWeight: 600
}

/**
 * Confirmation step includes summary information on the install.
 */
const ConfirmationStep = ({
  activeStep,
  index,
  installation,
  onActiveStep,
  onChange,
  onValid,
  ...rest
}) => {
  const newDevices = installation?.account?.devices?.filter(d => d.isNew === true)
  /** Summary of device(s) being installed */
  const installing = newDevices && (
    <>
      You are{' '}
      <Box component='span' sx={spanStyle}>
        installing {newDevices.length} new device
        {newDevices.length > 1 ? 's' : ''}
      </Box>
    </>
  )

  /** Summary of device(s) being removed. */
  const removing = installation?.removedDevices?.length && (
    <>
      , and{' '}
      <Box component='span' sx={spanStyle}>
        removing {installation.removedDevices.length} device
        {installation.removedDevices.length > 1 ? 's' : ''}
      </Box>
    </>
  )

  /** Determine the new CLI from the device. */
  const cli =
    installation?.account?.devices?.[0]?.phone ||
    installation?.account?.devices?.[0]?.msisdn

  /**
   * Destructure contact numbers we need for wrangling.
   *
   * If we don't set some default values during destructuring, it can cause
   * some pain when coming to test.
   */
  const {
    account: { cli1, customer: { phone1, phone2, mobile } } = { customer: {} }
  } = installation

  /**
   * Use the helper to wrangle contact numbers into the corect place. If a
   * previous CLI is passed, then that will be removed from contact nubmers.
   */
  const wrangledContactNumbers = CliHelper.expensiveWrangle({
    cli,
    phone1,
    phone2,
    mobile,
    prevCli: !installation?.keepCliContact && cli1
  })

  /**
   * Does the previous cli live in contacts
   */
  const cliInContacts = () => {
    return [
      phone1?.replace(/\D/g, ''),
      phone2?.replace(/\D/g, ''),
      mobile?.replace(/\D/g, '')
    ].some(value => value === cli1)
  }

  const contactNumbers = cliInContacts() && cli1 && cli1 !== cli && (
    <Grid container>
      <Alert severity='info'>
        A customer contact number ({cli1}) was previously associated with the
        OKEachDay service, and is marked to be removed from the contact
        information. This is usually fine, and indicates that a device is being
        replaced.{' '}
        <strong>
          However, if that is not the case, you can choose to keep the contact
          number.
        </strong>{' '}
        Ideally, a customer should have a landline or mobile number showing in
        "Phone".
      </Alert>

      <FormControlLabel
        control={<Checkbox checked={installation?.keepCliContact} />}
        label='Keep previous contact number'
        name='keepCliContact'
        onChange={val => onChange('keepCliContact', val)}
      />

      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Previous</TableCell>
            <TableCell>Now</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(phone1 || wrangledContactNumbers.phone1) && (
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell sx={{ ...tableStyle, color: 'error.main' }}>
                {phone1 || '—'}
              </TableCell>
              <TableCell sx={{ ...tableStyle, color: 'success.main' }}>
                {wrangledContactNumbers.phone1 || '—'}
              </TableCell>
            </TableRow>
          )}

          {(phone2 || wrangledContactNumbers.phone2) && (
            <TableRow>
              <TableCell>Other</TableCell>
              <TableCell sx={{ ...tableStyle, color: 'error.main' }}>
                {phone2 || '—'}
              </TableCell>
              <TableCell sx={{ ...tableStyle, color: 'success.main' }}>
                {wrangledContactNumbers.phone2 || '—'}
              </TableCell>
            </TableRow>
          )}

          {(mobile || wrangledContactNumbers.mobile) && (
            <TableRow>
              <TableCell>Mobile</TableCell>
              <TableCell sx={{ ...tableStyle, color: 'error.main' }}>
                {mobile || '—'}
              </TableCell>
              <TableCell sx={{ ...tableStyle, color: 'success.main' }}>
                {wrangledContactNumbers.mobile || '—'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  )

  const completeInstallationNote = (
    <Grid container>
      <Alert severity='info'>
        Completed appointments cannot be edited in the installer app. Please call the contact centre to make changes to this account.
      </Alert>
    </Grid>
  )

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='Final checks'
        onActiveStep={onActiveStep}
      />
      <StepContent>
        <Grid container spacing={3}>
          <Grid item sx={boxStyle}>
            {installing}
            {removing}.
          </Grid>
          <Grid item>{contactNumbers}</Grid>
          <Grid item xs={12}>{completeInstallationNote}</Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='notes'
              multiline
              label='Notes'
              onChange={val => onChange('account.notes', val)}
              value={installation?.account?.notes}
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id='medicalNotes'
              multiline
              label='Medical notes'
              onChange={val => onChange('account.medicalNotes', val)}
              value={installation?.account?.medicalNotes}
              variant='outlined'
            />
          </Grid>
        </Grid>
      </StepContent>
    </Step>
  )
}

export default ConfirmationStep
