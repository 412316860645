import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { addChange, getInstallation, patchInstallation } from 'app/store'
import { installationUpgrade } from 'app/store/actions/installationUpgrade'
import { AppointmentCardHeader } from 'components/molecules'
import { getDevice } from 'constants/devices'
import { allOutcomesMap, getOutcomeType, reasons, types, getOutcomeReasonString } from 'constants/outcomes'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

/**
 * Installation page. Provides a wizard-like experience for capturing customer
 * data, and verifying the success of an install.
 */
class EngineeringPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasIntegrity: true, outcomeValidate: true }
    this.handleChange = this.handleChange.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleInstall = this.handleInstall.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUpgrade = this.handleUpgrade.bind(this)
  }

  /**
   * The installation object, shorthand getter derived from props.
   */
  get installation () {
    return this?.props?.installation
  }

  /**
   * The appointment object, shorthand getter derived from props.
   */
  get appointment () {
    return this?.installation?.appointment
  }

  /**
   * Try to get a fresh instance of data when the componen mounts.
   */
  componentDidMount () {
    const { installation, getInstallation } = this.props
    getInstallation(installation?.id)
  }

  /**
   * Compares the integrity hash and prompts for a data refresh if integrity is
   * falsey.
   *
   * Also checks for truthy `installation.submitted` and starts a patch.
   */
  componentDidUpdate (prev) {
    const { addChange, installation, integrityCheck, patchInstallation } = this.props
    if (integrityCheck && !prev.integrityCheck) this.doIntegrityCheck()

    /**
     * Send a patch, only if the outcome has changed.
     */
    if (installation?.submitted) {
      patchInstallation(installation)
      addChange('submitted', false, installation.id)
      this.handleDone()
    }
  }

  /**
   * If network is available, then do a preflight check to make sure the account
   * data has integrity, and hasn't been modified since uploading.
   */
  doIntegrityCheck () {
    const { installation, integrityCheck } = this.props
    if (installation.integrityHash !== integrityCheck.integrityHash) {
      this.setState({ hasIntegrity: false })
    } else {
      this.setState({ hasIntegrity: true })
    }
  }

  /**
   * Handle changes to appointment data. This will pass all changes to the Redux
   * action, which is responsible for updating the state. Properties which
   * reference the state will then rerender components if necessary.
   *
   * Some extra wrangling here, to lookup the outcome reason from the detail
   * chosen.
   */
  async handleChange (event) {
    const { target } = event
    this.props.addChange(
      `appointment.${target.name}`,
      target.value,
      this.props.installation.id
    )

    if (target.name === 'outcomeDetail') {
      const reason = allOutcomesMap[target.value]
      const type = getOutcomeType(reason)

      this.props.addChange(
        'appointment.outcomeReason',
        reason,
        this.props.installation.id
      )

      this.props.addChange(
        'appointment.outcomeType',
        type,
        this.props.installation.id
      )
      await this.checkOutcomeValidation(target.value)
    }
  }

  /**
   * Handles the submission of final appointmet data. Does a bit of preflight
   * wrangling to determine outcome state. To ensure state is in sync, once
   * `submitted` is set to `true` the component update hook takes care of
   * initiating the network patch request.
   */
  async handleSubmit ({ type, reason, notes } = {}) {
    await this.checkOutcomeValidation(this.installation.appointment?.outcomeDetail)
    if(!this.state.outcomeValidate){
      return
    }
    const { addChange } = this.props
    const id = this.installation?.id
    if (this.appointment.outcomeReason === reasons.completed.COMPLETED) {
      addChange('appointment.completed', true, id)
    }
    addChange(
      'outcome.type',
      getOutcomeType(this.appointment.outcomeReason),
      id
    )
    addChange('outcome.reason', this.appointment.outcomeReason, id)
    addChange(
      'appointment.comments',
      this.appointment.comments.trim(),
      id
    )
    addChange('submitted', true, id)
  }

  /**
   * Handle final wrangling of data. This happens AFTER data has been sent for
   * patching. It is essentially doing an optimistic update of data which is
   * expected to be mutated on the server-side.
   */
  handleDone () {
    const { addChange, history, installation } = this.props

    const outcomes = installation.outcomes?.slice() ?? []
    outcomes.push(installation.outcome)

    addChange('outcomes', outcomes, installation.id)
    addChange('submitted', false, installation.id)

    history.push(
      `/mass/${this.props.mass.id}/collection/${this.props.collection.id}`
    )
  }

  /**
   * If updated account data has been found, then this will dispatch an action
   * to patch it in to the state.
   */
  handleUpgrade () {
    const { integrityCheck, installationUpgrade } = this.props
    installationUpgrade(integrityCheck)
    this.setState({ hasIntegrity: true })
  }

  /**
   * Reroute to the install stepper.
   */
  handleInstall () {
    const path = [
      '/mass',
      this.props.mass.id,
      'collection',
      this.props.collection.id,
      'installation',
      this.installation.id,
      'step/0'
    ]
    this.props.history.push(path.join('/'))
  }

  checkOutcomeValidation(outcomeDetail) {
    if (allOutcomesMap[outcomeDetail]) {
      this.setState({ outcomeValidate: true })
    } else {    
      this.setState({ outcomeValidate: false })
    }
  }

  /**
   * Style object for box text styling
   */
  get boxStyle () {
    return { fontSize: 18, lineHeight: 1.65 }
  }

  /**
   * Style object for highlighting inline spans.
   */
  get spanStyle () {
    return {
      backgroundColor: theme => alpha(theme.palette.secondary.main, 0.05),
      borderBottom: '2px solid',
      borderColor: theme => alpha(theme.palette.secondary.main, 0.15),
      color: 'secondary.main',
      paddingBottom: 0.25,
      paddingLeft: 0.5,
      paddingRight: 0.5
    }
  }

  render () {
    if (!this.installation) return null

    return (
      <Container mt={3} mb={3}>
        <Box mb={3}>
          <Card>
            <AppointmentCardHeader {...this.props} />

            <CardContent>
              <Box sx={this.boxStyle}>
                This appointment is to{' '}
                <Box component='span' sx={this.spanStyle}>
                  {this.appointment?.issue}
                </Box>
                . The details are{' '}
                <Box component='span' sx={this.spanStyle}>
                  {this.appointment?.issueDetails}
                </Box>
                .
              </Box>

              {this.installation?.account?.devices && (
                <Box sx={{ fontSize: 18, lineHeight: 1.65, marginTop: 1 }}>
                  There is a record of{' '}
                  <Box component='span' sx={this.spanStyle}>
                    {this.installation?.account?.devices.length}{' '}
                    {this.installation?.account?.devices.length > 1
                      ? 'devices'
                      : 'device'}
                  </Box>{' '}
                  installed at this property:{' '}
                  {this.installation?.account?.devices.map(device => (
                    <Box component='span' key={device} sx={this.spanStyle}>
                      {getDevice(device.type)?.title} ({device.msisdn})
                    </Box>
                  ))}
                </Box>
              )}
            </CardContent>

            <Divider />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='outcomeDetail'
                    label='Outcome'
                    name='outcomeDetail'
                    onChange={this.handleChange}
                    select
                    value={this.installation.appointment?.outcomeDetail || getOutcomeReasonString(this.installation.appointment?.outcomeReason) || ''}
                    error={!this.state.outcomeValidate}
                    helperText={!this.state.outcomeValidate ? "Please select an outcome" : ""}
                    disabled={this.installation?.outcome?.type === types.COMPLETED}
                  >
                    {Object.entries(allOutcomesMap).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='comments'
                    multiline
                    name='comments'
                    label='Comments'
                    onChange={this.handleChange}
                    rows={5}
                    disabled={this.installation?.outcome?.type === types.COMPLETED}
                    value={this.installation.appointment?.comments?.trim() 
                      ? this.installation.appointment.comments 
                      : this.installation?.account?.notes || ""}
                  />
                </Grid>

                <Grid item container spacing={2} alignItems='center'>
                  <Grid item sm>
                    <Button
                      color='secondary'
                      onClick={this.handleSubmit}
                      variant='contained'
                      disabled={this.installation?.outcome?.type === types.COMPLETED}
                    >
                      Submit
                    </Button>
                  </Grid>

                  <Grid item sm textAlign='right'>
                    <Button onClick={this.handleInstall} variant='contained' disabled={this.installation?.outcome?.type === types.COMPLETED}>
                      Install or replace a device
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match.params.massId]
  if (!mass) return {}

  const collection = mass.collections.find(
    c => parseInt(c.id) === parseInt(ownProps.match.params.collectionId)
  )
  const installation = collection.installations.find(
    i => parseInt(i.id) === parseInt(ownProps.match.params.installationId)
  )

  return {
    collection: collection,
    installation: installation,
    mass: mass,
    offline: state.offline,
    integrityCheck: state.integrityCheck
  }
}

const mapDispatchToProps = dispatch => ({
  addChange: (name, value, id) => dispatch(addChange(name, value, id)),
  installationUpgrade: effect => dispatch(installationUpgrade(effect)),
  getInstallation: id => dispatch(getInstallation(id)),
  patchInstallation: effect => dispatch(patchInstallation(effect))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EngineeringPage)
)
